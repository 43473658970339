import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Logo from '../../assets/images/GW_LOGO.png'

const StyledAppBar = styled(AppBar)`
  background-color: #FFF;
  color: #8DC63F;
`
const StyledLogo = styled.img`
  cursor: pointer;
  max-width: 110px;
  vertical-align: middle;
`
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const StyledFooter = styled.footer`
  align-items: center;
  background-color: #373737;
  color: #FFF;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-top: auto;
`

const Layout = (props) => {
  const history = useHistory()
  const { children } = props

  function handleClickLogo () {
    history.push('/')
  }

  return (
    <StyledWrapper>
      <StyledAppBar position='static'>
        <Toolbar disableGutters>
          <Container maxWidth='lg'>
            <StyledLogo alt='game-wallet' src={Logo} onClick={handleClickLogo} />
          </Container>
        </Toolbar>
      </StyledAppBar>
      {
        children
      }
      <StyledFooter>
        <p>{`Copyright: ${new Date().getFullYear()} | GameWallet | All Rights Reserved`}</p>
      </StyledFooter>
    </StyledWrapper>
  )
}

export default Layout
