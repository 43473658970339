// Utility Functions
import FiftyK from '../assets/images/50K.jpg'
import EightyK from '../assets/images/80K.jpg'
import OneHundredK from '../assets/images/100K.jpg'
import TwoHundredK from '../assets/images/200K.jpg'
import ThreeHundredK from '../assets/images/300K.jpg'
import FiveHundredK from '../assets/images/500K.jpg'
import OneM from '../assets/images/1M.jpg'
import TwoM from '../assets/images/2M.jpg'

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function parseGameCardAmount (gameCard) {
  switch (gameCard) {
    case '50K':
      return 50000
    case '80K':
      return 80000
    case '100K':
      return 100000
    case '200K':
      return 200000
    case '300K':
      return 300000
    case '500K':
      return 500000
    case '1M':
      return 1000000
    case '2M':
      return 2000000
    default:
      break
  }
}

function renderProductImage (productName) {
  switch (productName) {
    case '50K':
      return FiftyK
    case '80K':
      return EightyK
    case '100K':
      return OneHundredK
    case '200K':
      return TwoHundredK
    case '300K':
      return ThreeHundredK
    case '500K':
      return FiveHundredK
    case '1M':
      return OneM
    case '2M':
      return TwoM
    default:
      break
  }
}

export {
  sleep,
  parseGameCardAmount,
  renderProductImage
}
