import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import ProductTileOne from '../../assets/images/product-bg-icon-tile-one.png'
import ProductTileTwo from '../../assets/images/product-bg-icon-tile-two.png'
import CurrencyIcon from '../../assets/images/currency.png'
import MailIcon from '../../assets/images/mail.png'
import FBIcon from '../../assets/images/facebook.png'
import RoomIcon from '@material-ui/icons/Room'
import PhoneIcon from '@material-ui/icons/Phone'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { sleep, renderProductImage } from '../../utils/utils'
import BannerLogo from '../../assets/images/BANNER_LOGO.png'

const StyledBannerSection = styled.section`
  background-color: #1E1E1E;
  border-bottom: 4px solid #8DC63F;

  @media (max-width: 600px) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`
const StyledBanner = styled.div`
  align-items: center;
  display: flex;
  height: 428px;
  justify-content: space-between;
  padding: 0 24px;

  @media (max-width: 600px) {
    padding: 0;
    height: 350px;
  }
`
const StyledBannerText = styled.div`
  margin: 0 0 30px 50px;

  p {
    color: #FFF;
    font-size: 50px;
  }

  p:nth-child(1),
  p:nth-child(3) {
    line-height: 1;
  }

  p:nth-child(2) {
    line-height: 1.5;
  }

  p:nth-child(4) {
    color: #C9C9C9;
    font-size: 30px;
  }

  span {
    color: #8DC63F;
  }

  @media (max-width: 600px) {
    margin: 0;

    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(3) {
      font-size: 25px;
    }
    p:nth-child(4) {
      font-size: 15px;
    }
  }

  @media (min-width: 600px) and (max-width: 992px) {
    margin: 0;

    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(3) {
      font-size: 35px;
    }
    p:nth-child(4) {
      font-size: 25px;
    }
  }
`
const StyledBannerImage = styled.img`
  height: auto;
  margin-right: 93px;
  max-width: 100%;

  @media (max-width: 600px) {
    margin: 0;
    max-width: 180px;
  }

  @media (min-width: 600px) and (max-width: 992px) {
    margin: 0;
    max-width: 300px;
  }
`
const StyledProductListSection = styled.section`
  margin-top: 90px;
  padding: 0 48px;
  position: relative;

  &:after {
    background: url(${ProductTileOne}) no-repeat;
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    right: 0;
    top: 100px;
    max-width: 100px;
    width: 100%;
    z-index: 0;
  }

  @media (max-width: 600px) {
    margin-top: -80px;

    &:after {
      right: -10px;
      top: 160px;
    }
  }

  @media (max-width: 992px) {
    padding: 0;
  }
`
const StyledProductTitle = styled(Typography)`
  color: #8DC63F;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`
const StyledProductSubTitle = styled(Typography)`
  color: #4D4D4D;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    display: none;
  }
`
const StyledProductColumn = styled.div`
  background-color: ${props => props.productName === props.selectedProduct ? '#8DC63F' : '#FFF'};
  border-radius: 12px;
  box-shadow: 0px 1px 10px -4px rgba(112,112,112,1);
  cursor: pointer;
  margin: 0 auto 40px;
  max-width: 192px;
  padding: 11px;
  position: relative;
  width: 100%;
  z-index: 1;

  img {
    height: 140px;
    max-width: 190px;
    width: 100%;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      background: url(${CurrencyIcon}) no-repeat;
    }
  }

  @media (max-width: 600px) {
    margin: 0 auto 15px;
  }
`
const StyledHowToBuySection = styled.section`
  margin: 40px 0;
  padding: 0 48px;
  position: relative;

&:before {
    background: url(${ProductTileTwo}) no-repeat;
    content: '';
    display: block;
    height: 50px;
    left: 0;
    position: absolute;
    top: -100px;
    width: 50px;
  }

  @media (max-width: 600px) {
    &:before {
      left: -10px;
    }
  }

  @media (max-width: 992px) {
    padding: 0;
  }
`
const StyledHowToBuyTitle = styled(Typography)`
  color: #8DC63F;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
`
const StyledHowToBuyText = styled.p`
  color: #4D4D4D;
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const StyledContactUsSection = styled.section`
  background-color: #F5F5F5;
  padding: 40px 0;
`
const StyledContactUsContent = styled.div`
  padding: 0 48px;

  @media (max-width: 992px) {
    padding: 0;
  }
`
const StyledContactUsTitle = styled(Typography)`
  color: #8DC63F;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
`
const StyledContactUsText = styled.p`
  color: #4D4D4D;
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const StyledContactUsAddressAndEmail = styled.div`
  padding-top: 25px;

  p {
    color: #4D4D4D;
    font-size: 20px;
    margin-bottom: 20px;
  }

  p:nth-child(1) {
    svg {
      margin-right: 13px;
      vertical-align: middle;
      width: 30px;
    }
  }

  p:nth-child(2) {
    cursor: pointer;

    > a {
      &:before {
        background: url(${MailIcon}) no-repeat;
        content: '';
        display: inline-block;
        height: 30px;
        margin-right: 15px;
        vertical-align: top;
        width: 30px;
      }
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 992px) {
    p:nth-child(1) {
      display: flex;
    }
  }

  @media (max-width: 1200px) {
    p:nth-child(2) {
      margin: 0;
    }
  }
`
const StyledContactUsZaloAndFacebook = styled.div`
  padding-top: 25px;

  p {
    color: #4D4D4D;
    font-size: 20px;
    margin-bottom: 20px;
  }

  p:nth-child(1) {
    svg {
      margin-right: 15px;
      vertical-align: middle;
      width: 30px;
    }
  }

  p:nth-child(2) {
    cursor: pointer;

    > a {
      &:before {
        background: url(${FBIcon}) no-repeat;
        content: '';
        display: inline-block;
        height: 30px;
        margin: 0 9px;
        vertical-align: top;
        width: 30px;
      }
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const Home = () => {
  const history = useHistory()
  const [selectedProduct, setSelectedProduct] = useState(undefined)

  const productList = [
    {
      name: '50K',
      value: '50,000'
    },
    {
      name: '80K',
      value: '80,000'
    },
    {
      name: '100K',
      value: '100,000'
    },
    {
      name: '200K',
      value: '200,000'
    },
    {
      name: '300K',
      value: '300,000'
    },
    {
      name: '500K',
      value: '500,000'
    },
    {
      name: '1M',
      value: '1,000,000'
    },
    {
      name: '2M',
      value: '2,000,000'
    }
  ]

  async function handleClickProduct (productName) {
    setSelectedProduct(productName)
    await sleep(100)
    history.push(`/checkout/${productName}`)
  }

  return (
    <>
      <StyledBannerSection>
        <Container maxWidth='lg'>
          <StyledBanner>
            <StyledBannerText>
              <p>OFFERING A <span>HIGHER</span></p>
              <p><span>REVENUE</span> SHARING</p>
              <p>PLAN.</p>
              <p>Can earn upto 8%</p>
            </StyledBannerText>
            <StyledBannerImage alt='banner-logo' src={BannerLogo} />
          </StyledBanner>
        </Container>
      </StyledBannerSection>
      <section>
        <Container maxWidth='lg'>
          <StyledProductListSection>
            <StyledProductTitle variant='h1'>
              PRODUCT
            </StyledProductTitle>
            <StyledProductSubTitle variant='subtitle1'>
              Gamewallet offers the hottest HOT games cards with the highest return in the market.<br />
              All of the transaction data are encrypted, secure, fast and confidential
            </StyledProductSubTitle>
            <Container maxWidth='md' disableGutters>
              <Grid container spacing={2}>
                {
                  productList.map((product, i) => (
                    <Grid item xs={6} sm={4} md={3} key={i}>
                      <StyledProductColumn onClick={() => handleClickProduct(product.name)} productName={product.name} selectedProduct={selectedProduct}>
                        <img alt={product.name} src={renderProductImage(product.name)} />
                        <p>{product.value}</p>
                      </StyledProductColumn>
                    </Grid>
                  ))
                }
              </Grid>
            </Container>
          </StyledProductListSection>
          <StyledHowToBuySection>
            <StyledHowToBuyTitle variant='h1'>
              HOW TO BUY
            </StyledHowToBuyTitle>
            <StyledHowToBuyText>
              1. Select games card and number of quantity that you want to purchase.<br />
              2. Enter email and verification email to receive game card information.<br />
              3. Click on checkout to redirect to the payment page.<br />
              4. After the transaction is completed, the game card information is displayed on the payment summary page and sending its copy to your email.
            </StyledHowToBuyText>
          </StyledHowToBuySection>
        </Container>
      </section>
      <StyledContactUsSection>
        <Container maxWidth='lg'>
          <StyledContactUsContent>
            <StyledContactUsTitle variant='h1'>
           CONTACT US
            </StyledContactUsTitle>
            <StyledContactUsText>
            24/7 online customer service to make sure your online purchase running smoothly.<br />
            If there is any problem or question, please contact us via:
            </StyledContactUsText>
            <Grid container spacing={3}>
              <Grid item lg={8}>
                <StyledContactUsAddressAndEmail>
                  <p><RoomIcon /> <span>11 Võ Thị Sáu, Phường 6, Quận 3, Thành phố Hồ Chí Minh 700000, Vietnam</span></p>
                  <p><a href='mailto:gamewalletsc@gmail.com'>gamewalletsc@gmail.com</a></p>
                </StyledContactUsAddressAndEmail>
              </Grid>
              <Grid item lg={4}>
                <StyledContactUsZaloAndFacebook>
                  <p><PhoneIcon />Zalo: 0774858164</p>
                  <p><a href='https://www.facebook.com/scratchcard.gamewallet.1?__tn__=,dlC-R-R&eid=ARAsrC5x89_60oNlS8j5diiFHUzhY9yVECUCZl4nVuYPYnPJrEf4Ipn8umpI6wpuIciiMgJeUANaYZhM&hc_ref=ARSvg7Jo5K7Y8w1fypt6buODTUEBnhtv3vyfzctkatotfMncxPtDH7nAfnoOd2cPVd0' target='_blank' rel='noreferrer noopener'>gamewalletsc@gmail.com</a></p>
                </StyledContactUsZaloAndFacebook>
              </Grid>
            </Grid>
          </StyledContactUsContent>
        </Container>
      </StyledContactUsSection>
    </>
  )
}

export default Home
