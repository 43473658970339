import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }

    body {
      background-color: #FFF;
    }
`
