import React, { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import ScrollToTopMount from '../../components/ScrollToTopMount'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import { Alert, AlertTitle } from '@material-ui/lab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const REACT_APP_HUB_URL = process.env.REACT_APP_HUB_URL
const REACT_APP_START_HUB_CONNECTION = process.env.REACT_APP_START_HUB_CONNECTION
const REACT_APP_METHOD_HUB_CONNECTION = process.env.REACT_APP_METHOD_HUB_CONNECTION

const StyledContainerPadding = styled.div`
  padding: 0 48px;

  @media (max-width: 992px) {
    padding: 0;
  }
`
const StyledProgressContainer = styled.div`
  margin: 30px 0;
  text-align: center;

  > .MuiCircularProgress-colorPrimary {
    color: #8DC63F;
  }
`
const StyledErrorContainer = styled.div`
  color: #4D4D4D;
  margin-top: 30px;

  h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  h6 {
    font-size: 16px;
    font-weight: 500;
  }
`
const StyledWarningText = styled(Alert)`
  margin-top: 15px;
`
const StyledStatusBox = styled(Alert)`
  margin-top: 30px;

  > .MuiAlert-icon {
    font-size: 40px;
  }

  > .MuiAlert-message {
    font-size: 20px;

    div {
      font-size: 25px;
    }
  }
`
const StyledDownloadButton = styled.button`
  background: none;
  border: 0;
  color: #4D4D4D;
  cursor: pointer;
  font-size: 20px;

  &:focus {
    outline: 0;
  }

  &:hover {
    border-bottom: 2px solid #4D4D4D;
  }
`
const StyledClipboardText = styled.span`
  cursor: pointer;
`
const TableWrapper = styled.div`
  margin: 30px 0;
`
const StyledTable = styled(Table)`
  border: 1px solid #000;
`
const StyledTableHeadCell = styled(TableCell)`
  background-color: #8DC63F;
  border: 1px solid lightgray;
  color: ${props => props.theme.palette.common.white};
  font-size: 20px;
`
const StyledTableBodyCell = styled(TableCell)`
  border: 1px solid lightgray;
  color: #4D4D4D;
  font-size: 16px;
`

const Confirmation = (props) => {
  const reference = props.location.search.replace('?id=', '')
  const session = `SC-WEBSITE-${reference}`
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [establishConnection, setEstablishConnection] = useState(false)
  const [responseData, setResponseData] = useState({
    scratchCards: [],
    status: undefined,
    statusMessage: ''
  })
  const [tooltipText, setTooltipText] = useState('')
  const columns = [
    { id: 'serialNo', label: 'Serial Number' },
    { id: 'pin', label: 'Pin' }
  ]

  function handleOnCopyClipboard (text) {
    setTooltipText(text)
  }

  function handleScratchCardSuccess (results) {
    setResponseData(results)
  }

  function handleToolTipClose () {
    setTooltipText('')
  }

  function handleDownloadGeneratedCodes () {
    const csvRows = []

    // get the headers
    const headers = Object.keys(responseData.scratchCards[0])
    // Converts headers to Pascal Case
    const newHeaders = headers.reduce((accu, curr) => {
      let newCurr = ''
      if (curr) {
        newCurr = curr.replace(/([A-Z])/g, ' $1') // insert a space before all caps
          .replace(/^./, (str) => { return str.toUpperCase() }) // uppercase the first character
        accu.push(newCurr)
      }
      return accu
    }, [])

    csvRows.push(newHeaders.join(','))

    // loop over the rows
    for (const row of responseData.scratchCards) {
      const rows = headers.map(header => {
        return '="' + row[header] + '"'
      })
      csvRows.push(rows)
    }

    // download the csv table
    const csvTable = csvRows.join('\n')
    const blob = new Blob([csvTable], { type: 'text/csv' }) // eslint-disable-line
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', 'Generated Codes.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  function renderStatusAlert () {
    if (responseData.status === 6 || responseData.status === 7) {
      return 'success'
    } else if (responseData.status === 1) {
      return 'info'
    } else {
      return 'error'
    }
  }

  function renderStatusAlertMessage () {
    if (responseData.status === 6) {
      return (
        <>
          <AlertTitle>Transaction Successful!</AlertTitle>
          Your generated codes— <StyledDownloadButton onClick={handleDownloadGeneratedCodes}>Download</StyledDownloadButton>
        </>
      )
    } else if (responseData.status === 7) {
      return (
        <>
          <AlertTitle>Transaction Successful!</AlertTitle>
          {responseData.statusMessage}
        </>
      )
    } else if (responseData.status === 1) {
      return (
        <>
          <AlertTitle>Transaction Pending!</AlertTitle>
          {responseData.statusMessage}
        </>
      )
    } else {
      return (
        <>
          <AlertTitle>Transaction Failed!</AlertTitle>
          {responseData.statusMessage}
        </>
      )
    }
  }

  const RenderDisplayStatus = () => {
    return (
      <>
        {
          responseData.status === 6 &&
            <StyledWarningText severity='warning'>This page expire after window is closed, please download or copy down the serial number and pin. <br />
              The copy of information has been sent out to your email address
            </StyledWarningText>
        }
        <StyledStatusBox severity={renderStatusAlert()}>
          {
            renderStatusAlertMessage()
          }
        </StyledStatusBox>
        {
          responseData.status === 6 &&
            <TableWrapper>
              <TableContainer>
                <StyledTable size='medium'>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableHeadCell
                          key={column.id}
                        >
                          {column.label}
                        </StyledTableHeadCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {responseData.scratchCards.map((row) => {
                      return (
                        <TableRow hover key={row.serialNo}>
                          {columns.map((column) => {
                            const value = row[column.id]

                            return (
                              <StyledTableBodyCell key={column.id}>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true
                                  }}
                                  onClose={handleToolTipClose}
                                  open={value === tooltipText}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  placement='right'
                                  arrow
                                  title='link copied'
                                >
                                  <CopyToClipboard text={value} onCopy={(text) => handleOnCopyClipboard(text)}>
                                    <StyledClipboardText>{value}</StyledClipboardText>
                                  </CopyToClipboard>
                                </Tooltip>
                              </StyledTableBodyCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </TableWrapper>
        }
      </>
    )
  }

  const RenderDisplayResults = () => {
    if (!establishConnection && !errorMessage) {
      return (
        <StyledProgressContainer>
          <CircularProgress />
        </StyledProgressContainer>
      )
    } else if (!establishConnection && errorMessage) {
      return (
        <StyledErrorContainer>
          <Typography variant='h1'>
            {errorMessage.title}
          </Typography>
          <Typography variant='subtitle1'>
            {errorMessage.message}
          </Typography>
        </StyledErrorContainer>
      )
    } else {
      return <RenderDisplayStatus />
    }
  }

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_HUB_URL)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build()

    connection.on(REACT_APP_METHOD_HUB_CONNECTION, handleScratchCardSuccess)
    connection.onreconnected(async (e) => {
      await connection.invoke(REACT_APP_START_HUB_CONNECTION, session, reference)
    })

    async function start () {
      try {
        await connection.start()
        await connection.invoke(REACT_APP_START_HUB_CONNECTION, session, reference)
        setEstablishConnection(true)
      } catch (error) {
        setEstablishConnection(false)
        setErrorMessage({
          title: 'Internal Server Error',
          message: 'The server encountered an internal error or misconfiguration and was unable to complete you request.\n Please contact the server administrator or reload your browser.'
        })
      }
    }

    connection.onclose(async () => {
      await start()
    })

    // Start the connection
    start()
  }, [session, reference])

  return (
    <>
      <ScrollToTopMount />
      <Container maxWidth='lg'>
        <StyledContainerPadding>
          <RenderDisplayResults />
        </StyledContainerPadding>
      </Container>
    </>
  )
}

export default Confirmation
