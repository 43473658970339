import React, { useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {
  useParams
} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm } from 'react-hook-form'
import { parseGameCardAmount, renderProductImage } from '../../utils/utils'
import ScrollToTopMount from '../../components/ScrollToTopMount'
import API from '../../utils/api'

const REACT_APP_DEPOSIT_URL = process.env.REACT_APP_DEPOSIT_URL

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const StyledSectionTitle = styled.section`
  border-bottom: 5px solid #6D6D6D;
  padding: 30px 0 15px;
`
const StyledContainerPadding = styled.div`
  padding: 0 48px;

  @media (max-width: 992px) {
    padding: 0;
  }
`
const StyledTitle = styled(Typography)`
  color: #4D4D4D;
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
  text-align: center;

  span {
    position: relative;

    &:after {
      align-items: center;
      border-radius: 50%;
      box-shadow: 0px 1px 10px -4px rgba(112,112,112,1);
      content: '${props => props.gamecardquantity}';
      display: flex;
      font-size: 20px;
      height: 25px;
      justify-content: center;
      position: absolute;
      right: -30px;
      top: -7px;
      width: 25px;
    }
  }

  @media (max-width: 600px) {
    font-size: 25px;
  }
`
const StyledSectionItemDetails = styled.section`
  border-bottom: 1px solid #636363;
  padding: 25px 0;
`
const StyledSectionItemQuantity = styled.section`
  padding: 35px 0;
`
const StyledItemDetailsTitle = styled(Typography)`
  color: #4D4D4D;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const StyledItemDetailsSubtitle = styled.p`
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 500;
`
const StyledAmountCards = styled.span`
  font-weight: bold;
`
const StyledItemQuantityTitle = styled(Typography)`
  color: #4D4D4D;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const StyledItemQuantityImage = styled.img`
  height: auto;
  margin-left: 40px;
  max-width: 170px;
  width: 100%;

  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
  }
`
const StyledItemQuantityButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 120px;
    margin-top: 10px;
  }
`
const StyledItemQuantityButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 180px;
  width: 100%;

  button {
    border-radius: 4px;
    border: 2px solid #4D4D4D;
    height: 50px;
    width: 50px;
  }

  input {
    background-color: #4D4D4D;
    border-radius: 4px;
    border: 1px solid #4D4D4D;
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    height: 55px;
    text-align: center;
    width: 55px;
  }
`
const StyledInputGameCard = styled.input`
  ::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
`
const StyledGameCardAmount = styled.p`
  color: #4D4D4D;
  font-size: 25px;
  font-weight: bold;
`
const StyledTotalAmountSection = styled.section`
  border-bottom: 1px solid #636363;
  border-top: 1px solid #636363;
`
const StyledTotalAmountContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 70px;
  padding: 25px 0;

  p {
    color: #4D4D4D;
    font-size: 25px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`
const StyledEmailFormSection = styled.section`
  margin: 50px 0 70px;
`
const StyledEmailForm = styled.form`
  margin: 0 auto;
  max-width: 50%;
  text-align: center;

  input {
    border-radius: 4px;
    border: 1px solid #4D4D4D;
    font-size: 20px;
    height: 70px;
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
  }

  button {
    background-color: #8DC63F;
    border-radius: 50px;
    border: 0;
    color: #FFF;
    cursor: pointer;
    font-size: 20px;
    height: 65px;
    max-width: 220px;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`
const StyledErrorMessages = styled.p`
  color: #f44336;
  margin: 0 0 10px;
  padding-left: 10px;
  text-align: left;
`

const Checkout = () => {
  const { gameCard } = useParams()
  const { register, errors, handleSubmit, watch } = useForm()
  const [gameCardQuantity, setGameCardQuantity] = useState(parseInt(window.sessionStorage.getItem('gameCardQuantity')) || 1)
  const [loading, setLoading] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')
  const [toaster, setToaster] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right'
  })
  const getGameCardQuantity = window.sessionStorage.getItem('gameCardQuantity')
  const getGameCardValue = window.sessionStorage.getItem('gameCardValue')
  const emailRef = useRef({})
  emailRef.current = watch('email', window.sessionStorage.getItem('email') || '')

  function handleDecrementAmount () {
    setGameCardQuantity(gcq => gcq - 1)
    window.sessionStorage.setItem('gameCardQuantity', gameCardQuantity - 1)
    window.sessionStorage.setItem('gameCardValue', parseGameCardAmount(gameCard) * (gameCardQuantity - 1))
  }

  function handleIncrementAmount () {
    setGameCardQuantity(gcq => gcq + 1)
    window.sessionStorage.setItem('gameCardQuantity', gameCardQuantity + 1)
    window.sessionStorage.setItem('gameCardValue', parseGameCardAmount(gameCard) * (gameCardQuantity + 1))
  }

  function handleCloseToaster () {
    setToaster({ ...toaster, open: false })
  }

  async function handleSubmitForm ({ email }) {
    const denomination = parseGameCardAmount(gameCard)
    setLoading(true)

    try {
      const response = await API({
        method: 'POST',
        url: '/scratchcard/checkout',
        data: {
          quantity: gameCardQuantity,
          denomination: denomination,
          email: email
        }
      })
      window.sessionStorage.setItem('email', email)
      window.location.href = REACT_APP_DEPOSIT_URL + response.data.param
    } catch (error) {
      setErrorResponse(error.message)
      setLoading(false)
      setToaster({ ...toaster, open: true })
    }
  }

  return (
    <>
      <ScrollToTopMount />
      <StyledSectionTitle>
        <StyledTitle variant='h1' gamecardquantity={getGameCardQuantity || 1}>
          <span>Your Cart</span>
        </StyledTitle>
      </StyledSectionTitle>
      <StyledSectionItemDetails>
        <Container maxWidth='lg'>
          <StyledContainerPadding>
            <StyledItemDetailsTitle variant='h2'>
            If there is any problem that you need our support to check for failed card transactions, please contact us via: <br />
            </StyledItemDetailsTitle>
            <StyledItemDetailsSubtitle>
            Email: <a href='mailto:gamewalletsc@gmail.com'>gamewalletsc@gmail.com</a><br />
            Limit Quantity per order: <StyledAmountCards>5 cards</StyledAmountCards>
            </StyledItemDetailsSubtitle>
          </StyledContainerPadding>
        </Container>
      </StyledSectionItemDetails>
      <StyledSectionItemQuantity>
        <Container maxWidth='lg'>
          <StyledContainerPadding>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5} md={4}>
                <StyledItemQuantityTitle variant='h2'>
                  Items:
                </StyledItemQuantityTitle>
                <StyledItemQuantityImage alt={gameCard} src={renderProductImage(gameCard)} />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <StyledItemQuantityButtonContainer>
                  <StyledItemQuantityButtons>
                    <IconButton onClick={handleDecrementAmount} disabled={getGameCardQuantity <= 1}>
                      <RemoveIcon />
                    </IconButton>
                    <StyledInputGameCard type='number' name='gameCardQuantity' readOnly value={getGameCardQuantity || 1} />
                    <IconButton onClick={handleIncrementAmount} disabled={getGameCardQuantity >= 5}>
                      <AddIcon />
                    </IconButton>
                  </StyledItemQuantityButtons>
                  <StyledGameCardAmount>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'VND' }).format(getGameCardValue || parseGameCardAmount(gameCard))}</StyledGameCardAmount>
                </StyledItemQuantityButtonContainer>
              </Grid>
            </Grid>
          </StyledContainerPadding>
        </Container>
      </StyledSectionItemQuantity>
      <StyledTotalAmountSection>
        <Container maxWidth='lg'>
          <StyledContainerPadding>
            <StyledTotalAmountContainer>
              <p>Total</p>
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'VND' }).format(getGameCardValue || parseGameCardAmount(gameCard))}</p>
            </StyledTotalAmountContainer>
          </StyledContainerPadding>
        </Container>
      </StyledTotalAmountSection>
      <StyledEmailFormSection>
        <Container maxWidth='lg'>
          <StyledContainerPadding>
            <StyledEmailForm onSubmit={handleSubmit(handleSubmitForm)}>
              <input
                type='text'
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                  }
                })}
                name='email'
                placeholder='Email'
              />
              <StyledErrorMessages>
                {errors.email?.type === 'required' && 'Please input email address'}
                {errors.email?.type === 'pattern' && 'Invalid email address'}
              </StyledErrorMessages>
              <input
                type='text'
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid verify email address'
                  },
                  validate: value => value === emailRef.current
                })}
                name='verifyEmail'
                placeholder='Verify Email'
              />
              <StyledErrorMessages>
                {errors.verifyEmail?.type === 'required' && 'Please input verify email address'}
                {errors.verifyEmail?.type === 'pattern' && 'Invalid verify email address'}
                {errors.verifyEmail?.type === 'validate' && 'The emails do not match'}
              </StyledErrorMessages>
              <button type='submit' disabled={loading}>
                {
                  loading ? <CircularProgress color='inherit' /> : 'Checkout'
                }
              </button>
            </StyledEmailForm>
          </StyledContainerPadding>
        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: toaster.vertical,
            horizontal: toaster.horizontal
          }}
          open={toaster.open}
          onClose={handleCloseToaster}
          key={toaster.vertical + toaster.horizontal}
          autoHideDuration={5000}
          message={errorResponse}
        >
          <Alert severity='error'>{errorResponse}</Alert>
        </Snackbar>
      </StyledEmailFormSection>
    </>
  )
}

export default Checkout
