// utils/API.js
import axios from 'axios'

const baseURL = process.env.REACT_APP_ENDPOINT

const API = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default API
