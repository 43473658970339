import React from 'react'
import GlobalStyles from '../../assets/styles/GlobalStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Layout from '../Layout'
import Home from '../Home'
import Checkout from '../Checkout'
import Confirmation from '../Confirmation'
import NotFound from '../NotFound'
import { parseGameCardAmount } from '../../utils/utils'

const theme = createMuiTheme()

export default function App () {
  return (
    <StyledThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <Router>
        <Switch>
          <Route exact path='/'>
            <Layout>
              <Home />
            </Layout>
          </Route>
          <Route
            path='/checkout/:gameCard'
            render={
              ({ match: { params } }) =>
                parseGameCardAmount(params.gameCard) ? <Layout><Checkout /></Layout> : <Redirect to='/' />
            }
          />
          <Route
            path='/confirmation'
            render={(routeProps) => {
              const confirmationId = new URLSearchParams(routeProps.location.search).get('id')
              if (confirmationId) {
                return (
                  <Layout><Confirmation {...routeProps} /></Layout>
                )
              } else {
                return (<NotFound />)
              }
            }}
          />
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </StyledThemeProvider>
  )
}
